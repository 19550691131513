import React, { useState } from 'react'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'
import './CSS/CustomerReviewCount.css'

export const CustomerReviewCount = () => {
    const [countup,setCounterup]=useState(false);
  return (
    <ScrollTrigger onEnter={()=>setCounterup(true)} onExit={()=>setCounterup(false)}>
    <div className='Count-container'>
        <div className="Count-row">
            <div className="Count-col">
                <h1>
                    {countup && <CountUp start={0} end={10} duration={10} delay={0}></CountUp>}
                    K+
                </h1>
                <p>Projects Completed</p>
            </div>
            <div className="Count-col">
                <h1>
                    {countup && <CountUp start={0} end={98} duration={10} delay={0}></CountUp>}
                    %
                </h1>
                <p>Satisfied Customer</p>
            </div>
            <div className="Count-col">
                <h1>
                {countup && <CountUp start={0} end={4.8} decimals={1} duration={10} delay={0} />}                </h1>
                <p>User Rating</p>
            </div>
            <div className="Count-col">
                <h1>
                    {countup && <CountUp start={0} end={15} decimalPlaces={1} duration={10} delay={0}></CountUp>}
                    +
                </h1>
                <p>Years of Experience</p>
            </div>
        </div>
    </div>
    </ScrollTrigger>
  )
}
