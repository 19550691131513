import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import './CSS/Whatsapp.css'

export const Whatsapp = () => {
  return (
    <div className='Whatsapp-container'>
    <a href="http://wa.me/+923219448697" target='blanket'>
    <FontAwesomeIcon icon={faWhatsapp} className='whatsapp'/>
    </a>
   </div>
  )
}
