import React from 'react'
import logo from '../Images/Navbar/logo.png'
import './CSS/Footer.css'
import {BookApointment} from "./BookApointment";
import { SocialIcons } from './SocialIcons';

export const Ending = (props) => {
  return (
   <>
    <footer >
            <div className='row'>
                <div className='col'>
                    <img src={logo} alt="logo" className='logo'/>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime inventore at alias repellat reiciendis porro distinctio doloribus voluptas consectetur, unde obcaecati? Delectus deserunt quod enim soluta excepturi totam voluptas sequi?</p>
                </div>
                <div className='col'>
                    <h3>Contact <div className="underline"><span></span></div></h3>
                    <p>Office #53, P (Paradise) Floor I.T Tower 73-E1 Hali Rd</p>
                    <p>Gulberg III Lahore, Pakistan</p>
                    <p className='email-id'>info@ar.com.pk</p>
                    <p className='landline'>Landline : <h4>+92 42 35710318</h4></p>
                    <p className='mobile'>Mobile :  <h4>+92 321 9483047 <br></br>+92 321 9448697</h4></p>
                </div>
                <div className='col'>
                    <h3>Links  <div className="underline"><span></span></div></h3>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">About</a></li>
                        <li><a href="#">Projects</a></li>
                        <li><a href="#">Products</a></li>
                        <li><a href="#">Solutions</a></li>
                        <li><a href="#">Contact</a></li>
                    </ul>
                </div>
                <div className='col'>
                    <h3>Send us a Message <div className="underline"><span></span></div></h3>
                    <BookApointment location={props.BookApointmentLocation}/>
                    <SocialIcons location="footer"/>
                </div>
            </div>
            <hr></hr>
            <p className='copyright'>© Advance Resources 2024 All right reserved.</p>
        </footer>
   </>
  )
}
