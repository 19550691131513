import React from 'react'
import './CSS/ProjectSlideshow.css';
import AOS from 'aos'
import 'aos/dist/aos.css'

function ProjectCard(props) {
  return (
    <a href='#' className='Project-items'>
    <img src={props.image} data-aos="zoom-in"></img>  {/* removing data-aos from image will add hover  */}
    <h3 className='project-items-heading'  data-aos="zoom-in" >{props.name}</h3>
    <ul className='project-items-details' data-aos="fade-up"> 
        <li>{props.text1}</li> 
        <li>{props.text2}</li> 
    </ul>
</a>  )
}

export default ProjectCard