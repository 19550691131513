import React, { useState, useEffect, useRef, useCallback } from 'react';
import './CSS/Slideshow.css';
import slide2 from "../Images/Slideshow/gateway2.png";
import slide3 from "../Images/Slideshow/PBX2.png";
import slide4 from "../Images/Slideshow/vc.png";
import slide5 from "../Images/Slideshow/meetingboard.webp";
import slide6 from "../Images/Slideshow/ipphone.jpg";
import slide7 from "../Images/Slideshow/3.webp";
import yeastar from "../Images/Slideshow/Yeastar_Logo.png";
import yealink from "../Images/Slideshow/Yealink-LOGO-Green.png";

export const Slideshow = () => {
  const sliding = [
    {
      slide: slide2,
      logo: yeastar,
      heading: 'Call Centre Solution',
      para: "Deliver exceptional customer services with all the agent and supervisor tools that drive faster call resolution and boost agent productivity.",
      customStyles: {
        container: { top: '25%', left: '10%' },
        logo:{},
        heading: { padding: '20px 0 0 20px' ,color: 'white'},
        para: { padding: "20px", lineHeight: "25px",color: 'white' },
      },
    },
    {
      slide: slide3,
      logo: yeastar,
      heading: 'P-Series Phone System',
      para: "P Series Available in Appliance, Software & Cloud Editions.",
      customStyles: {
        container: { top: '25%', left: '10%' },
        logo:{},
        heading: { padding: '20px 0 0 20px',color: 'white' },
        para: { padding: "20px", lineHeight: "25px",color: 'white' },
      },
    },
    {
      slide: slide4,
      logo: yealink,
      heading: 'Video Conferncing System',
      para: "Yealink video conference systems enhance collaboration experiences, whether you're working from home, workspace or any remote location.",
      customStyles: {
        container: { top: '26%', left: '10%' },
        logo:{paddingLeft:'20px'},
        heading: { padding: '50px 0 0 20px',color: 'white' },
        para: { padding: "30px 0 0 20px", lineHeight: "25px" ,color: 'white' },
      },
    },
    {
      slide: slide5,
      logo: yealink,
      heading: 'Meeting Board',
      para: "Yealink's interactive meeting boards enhance teamwork by enabling real-time content sharing and dynamic presentations, fostering collaborative environments.",
      customStyles: {
        container: { top: '26%', left: '10%' },
        logo:{paddingLeft:'20px'},
        heading: { padding: '50px 0 0 20px',color: 'white' },
        para: { padding: "30px 0 0 20px", lineHeight: "25px" ,color: 'white' },
      },
    },
    {
      slide: slide6,
      logo: yealink,
      heading: 'IP-Phone',
      para: "Yealink video conference systems enhance collaboration experiences, whether you're working from home, workspace or any remote location.",
      customStyles: {
        container: { top: '26%', left: '10%' },
        logo:{paddingLeft:'20px'},
        heading: { padding: '50px 0 0 20px',color: 'white' },
        para: { padding: "30px 0 0 20px", lineHeight: "25px" ,color: 'white' },
      },
    },
    {
      slide: slide7,
      logo: yealink,
      heading: 'Bluetooth Wireless Headset',
      para: "Applied industry-leading audio technologies, Yealink Headset Series delivers a crystal-clear, uninterrupted audio experience, even in noisy environments.",
      customStyles: {
        container: { top: '26%', left: '10%' },
        logo:{paddingLeft:'20px'},
        heading: { padding: '50px 0 0 20px',color: 'white' },
        para: { padding: "30px 0 0 20px", lineHeight: "25px" ,color: 'white' },
      },
    },
    // Add other slides here
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(Array(sliding.length).fill(false));
  const [animate, setAnimate] = useState(true);

  const timer = useRef(0);

  const goToSlide = useCallback((slideIndex) => {
    setCurrentIndex(slideIndex);
    setAnimate(false);
    setTimeout(() => setAnimate(true), 880); // Trigger reflow to restart animation
    const updatedClickedButtons = buttonClicked.map((_, index) => index === slideIndex);
    setButtonClicked(updatedClickedButtons);
  }, [buttonClicked]);

  const goToNextSlide = useCallback(() => {
    const checkLast = currentIndex === sliding.length - 1;
    const newIndex = checkLast ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    setAnimate(false);
    setTimeout(() => setAnimate(true), 880); // Trigger reflow to restart animation
    const updatedClickedButtons = buttonClicked.map((_, index) => index === newIndex);
    setButtonClicked(updatedClickedButtons);
  }, [currentIndex, buttonClicked, sliding.length]);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      goToNextSlide();
    }, 8000);

    return () => clearTimeout(timer.current);
  }, [currentIndex, goToNextSlide]);

  return (
    <div className='slidecontainer'>
      <div className='slideshow' style={{ backgroundImage: `url(${sliding[currentIndex].slide})` }}>
      </div>

      <div className={`slide-data ${animate ? 'animate' : ''}`} style={sliding[currentIndex].customStyles.container}>
        <img src={sliding[currentIndex].logo} alt="logo" style={sliding[currentIndex].customStyles.logo}/>
        <h1 style={sliding[currentIndex].customStyles.heading}>{sliding[currentIndex].heading}</h1>
        <p style={sliding[currentIndex].customStyles.para}>{sliding[currentIndex].para}</p>
        <button className="learn-more-button">
        <a href={sliding[currentIndex].link} >Learn More</a>
        </button>  
      </div>
      
      <div className='circlecontainer'>
        {sliding.map((slide, slideIndex) => (
          <div key={slideIndex} className="button-container" onClick={() => goToSlide(slideIndex)}>
            <div className={`button round ${buttonClicked[slideIndex] ? 'button-click' : ''}`}></div>
          </div>
        ))}
      </div>
    </div>
  );
};
