import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faArrowRight, faFaceGrinBeam, faMessage } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';
import { MessageSent } from './MessageSent';
import styles1 from './CSS/BookApointmentMainpage.module.css';
import styles2 from './CSS/BookApointmentContactpage.module.css';

export const BookApointment = (props) => {
  const styles = props.location === 'footer' ? styles1 : styles2;


  const [messageSent, setMessageSent] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_gb74yzt', 'template_5nbrhfs', form.current, {
        publicKey: 'O9hodK5iosCIeMvYA',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setMessageSent(true);
          e.target.reset();

          setTimeout(() => {
            setMessageSent(false);
          }, 2000);
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} className={styles.form}>
      {messageSent && <MessageSent />}
      <div className={styles.details}>
        <div className={styles.formItem}>
          <div className={styles.circle}>
            <FontAwesomeIcon icon={faFaceGrinBeam} className={styles.icon} />
          </div>
          <input type="text" name="to_name" placeholder="Enter your Name" required className={styles.input} />
        </div>

        <div className={styles.formItem}>
          <div className={styles.circle}>
            <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
          </div>
          <input type="email" name="from_name" placeholder="Enter your E-mail" required className={styles.input} />
        </div>

        <div className={styles.formItem}>
          <div className={styles.circle}>
            <FontAwesomeIcon icon={faMessage} className={styles.icon} />
          </div>
          <textarea name="message" placeholder="Enter your message" className={styles.textarea} />
        </div>
      </div>
      <button type="submit" className={styles.send}>
        <div className={styles.submit}>
          <FontAwesomeIcon icon={faArrowRight} size="2x" />
        </div>
      </button>
    </form>
  );
};
