import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import styles1 from './CSS/SocialIconsFooter.module.css'; // style for footer
import styles2 from './CSS/SocialIconsContactpage.module.css'; // style for contact page

export const SocialIcons = (props) => {
  const styles = props.location === 'footer' ? styles1 : styles2;
  

  return (
    <div className={styles.SocialIcons}>
      <div className={styles.container}>
        <a href="http://facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} className={styles.Icons} />
        </a>
      </div>
      <div className={styles.container}>
        <a href="http://twitter.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} className={styles.Icons} />
        </a>
      </div>
      <div className={styles.container}>
        <a href="http://instagram.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className={styles.Icons} />
        </a>
      </div>
      <div className={styles.container}>
        <a href="http://linkedin.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} className={styles.Icons} />
        </a>
      </div>
    </div>
  );
};

export default SocialIcons;
