import React, { useEffect } from 'react';
import './CSS/Intro.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears,faHeadset ,faEye} from '@fortawesome/free-solid-svg-icons';
import IntroBackground from '../Images/Intro/page-5.png';

export const Intro = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className='Intro-container'>
            <div className="Intro-row">
                <div className="Intro-col" data-aos="fade-right" data-aos-offset="300"  data-aos-once="false" data-aos-easing="ease-in-sine">
                    <h4>WHY CHOOSE US</h4>
                    <h1>Comprehensive IT Solutions for Your Business</h1>
                    <p>Our company specializes in providing top-notch security, VOIP, and IT infrastructure services to meet all your business needs. Here's why you should choose us:</p>
                    <div className="Intro-col-items">
                            <div className="Intro-col-item1">
                            <FontAwesomeIcon icon={faGears} className='Intro-icon' />
                            </div>
                        
                              
                            <div className="Intro-col-item2">
                              <h2>Seamless Integration</h2>                  
                              <p>Setting up your IT infrastructure and VOIP services has never been easier. Our team ensures a smooth and hassle-free setup process, tailored to your specific requirements.</p>
                            </div>
                    </div>
                    <div className="Intro-col-items">
                             <div className="Intro-col-item1">
                            <FontAwesomeIcon icon={faEye} className='Intro-icon' />
                            </div>
                      
                            <div className="Intro-col-item2">
                              <h2>Robust Security</h2>
                              <p>We prioritize the security of your business. Our comprehensive security solutions protect your data and ensure safe and secure transactions, giving you peace of mind.</p>
                            </div>
                    </div>
                    <div className="Intro-col-items">
                       
                            <div className="Intro-col-item1">
                            <FontAwesomeIcon icon={faHeadset} className='Intro-icon' />
                            </div>
                            <div className="Intro-col-item2">
                              <h2>24/7 Support</h2>
                              <p>Our dedicated support team is available around the clock to assist you with any issues or questions, providing reliable and prompt service.</p>
                            </div>
                       
                    </div>
                </div>
                <div className="Intro-col" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                  <div className="IntroBackground">
                  <img src={IntroBackground} alt="background" />
                  </div>
                </div>
            </div>
        </div>
    );
};
