import React, { useEffect } from 'react';
import './CSS/Services.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ServicesBackground from '../Images/Services/Servicesbackground.jpg';
import MSzoom from '../Images/Services/image.jpeg';
import Voip from '../Images/Services/voip.jpg';
import ITinfra from '../Images/Services/IT.jpg';
import surveilence from '../Images/Services/surveilence.jpg';
import network from '../Images/Services/network.jpg';
import Amc from '../Images/Services/AMC.jpg';
import webhosting from '../Images/Services/webhosting.jpg';
import disaster from '../Images/Services/disaster.png';
import meeting from '../Images/Services/meeting.jpg';
import ServiceCard from './ServiceCard';
import {Ending} from "./Ending";

export const ServicesAndSolutions = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className='services-container'>
            <h1 data-aos="zoom-in-down">Services</h1>
            <div className='services-grid'>
                <ServiceCard image={surveilence} onscroll="zoom-in-up" title="Surveilence Solutions" text="Safeguard your assets with Advance Resources' advanced surveillance solutions. Our CCTV systems, video analytics, and access control ensure comprehensive protection and operational safety."/>
                <ServiceCard image={Voip} onscroll="zoom-in-up" title="VOIP services" text="   Experience seamless communication with Advance Resources' cutting-edge VoIP services. Manage calls effortlessly, receive voicemails as emails, and stay connected on the go with our mobile integration."/>
                <ServiceCard image={ITinfra} onscroll="zoom-in-up" title="IT infrastructures" text="Build your business on a foundation of reliability and security with Advance Resources' expertly designed IT infrastructures. From robust servers to flexible cloud solutions, we tailor every setup for scalability and efficiency."/>
                <ServiceCard image={network} onscroll="zoom-in-up" title="Network Solutions" text="Enhance your business connectivity with Advance Resources' customized network solutions. Whether LAN, WAN, wireless, or VPN, we optimize performance and security to meet your specific needs."/>
                <ServiceCard image={Amc} onscroll="zoom-in-up" title="AMC Services" text="Keep your IT infrastructure running smoothly with Advance Resources' proactive AMC services. Benefit from regular inspections, preventive maintenance, and timely updates to minimize downtime and maximize reliability."/>
                <ServiceCard image={disaster} onscroll="zoom-in-up" title="Disaster Recovery" text="Ensure business continuity with Advance Resources' robust disaster recovery solutions. Our tailored plans include backup strategies and rapid data recovery, safeguarding your critical IT infrastructure against unforeseen disruptions."/>
                <ServiceCard image={webhosting} onscroll="zoom-in-up" title="Hosting Services" text="Trust Advance Resources for secure and scalable hosting solutions tailored to your business. Whether you need shared, dedicated, or cloud hosting, ensure high availability and data security without the hassle."/>
                <ServiceCard image={meeting} onscroll="zoom-in-up" title="Microsoft Meetings" text="Transform collaboration with Advance Resources' customized deployment of Microsoft Teams and Zoom. Enhance productivity with seamless video conferencing, messaging, screen sharing, and integration with essential tools."/>
             
               </div>
               <Ending BookApointmentLocation='footer'/>
        </div>
    );
};
