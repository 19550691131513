import React , {useEffect}from 'react'
import './CSS/ProjectSlideshow.css';
import AOS from 'aos'
import 'aos/dist/aos.css'
// import projectBackground from '../Images/Projects/ProjectBackground.jpg'
import bankislami from '../Images/Projects/Bankislami.png'
import PMC from '../Images/Projects/PMC.png'
import PakRailway from '../Images/Projects/PakistanRailway.png'
import Sabroso from '../Images/Projects/Sabroso.png'
import Telenor from '../Images/Projects/Telenor.png'
import DHA from '../Images/Projects/DHA.png'
import CancerCare from '../Images/Projects/CancerCare.png'
import ProjectCard from './ProjectCard';
// import Mari from '../Images/Projects/MPCLlogo.svg.png'

export const ProjectSlideshow = () => {
    useEffect(()=>
    {
        AOS.init();
    },[]);
  
    return (
    <div className='project-container'  >

        <h1 className='heading' data-aos="zoom-in-down">Projects</h1>
        <div className="project-container-data">
        <div className='project-container-slide'  >
            <ProjectCard image={bankislami} name="Bank Islami" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed"/>
            <ProjectCard image={PMC} name="Pakistan Medical Commision" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />
            <ProjectCard image={PakRailway} name="Pakistan Railway" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />
            <ProjectCard image={Telenor} name="Telenor MicroFinance Bank" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />
            <ProjectCard image={Sabroso} name="Sabroso" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />
            <ProjectCard image={DHA} name="DHA Islamabad-Rawalpindi" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />
            <ProjectCard image={CancerCare} name="Cancer Care Hospital" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />

        </div>
        <div className='project-container-slide'  >
            <ProjectCard image={bankislami} name="Bank Islami" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed"/>
            <ProjectCard image={PMC} name="Pakistan Medical Commision" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />
            <ProjectCard image={PakRailway} name="Pakistan Railway" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />
            <ProjectCard image={Telenor} name="Telenor MicroFinance Bank" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />
            <ProjectCard image={Sabroso} name="Sabroso" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />
            <ProjectCard image={DHA} name="DHA Islamabad-Rawalpindi" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />
            <ProjectCard image={CancerCare} name="Cancer Care Hospital" text1="Installation and cabling of Dahua Cameras was executed" text2="Installation and cabling of Dahua Cameras was executed" />

        </div>
      
       
        </div>
        
    </div>
  )
}
