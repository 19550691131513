import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


function ServiceCard(props) {
  useEffect(() => {
    AOS.init();
}, []);
  return (
    <div className='services-card' data-aos={props.onscroll} data-aos-easing="ease-out-cubic" data-aos-duration="500">
        <div className='top-card'>
           <img src={props.image} className='card-image' alt='Service 1' ></img>
        </div>
        <div className='bottom-card' >
            <h3 data-aos="fade-left" data-aos-easing="ease-out-cubic" data-aos-duration="800" data-aos-once="true" >{props.title}</h3>
            <p data-aos="fade-right" data-aos-easing="ease-out-cubic" data-aos-duration="800" data-aos-once="true">{props.text}</p>
            <button></button>
        </div>
    </div>
  )
}

export default ServiceCard