import React from 'react'
import { Navbar} from './Navbar'
import {Ending} from "./Ending";
import { BookApointment } from './BookApointment';
import "./CSS/Contact.css";
import background from "../Images/Contact/ContactBackground.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLocationDot,faEnvelope,faPhone} from '@fortawesome/free-solid-svg-icons'
import SocialIcons from './SocialIcons';


export const Contact = (props) => {
  return (
    <div>
      <Navbar variant="absolute"/>
      <div className='background'>
        <img src={background} alt="background" />
        <h2>Contact Us</h2>
        <p>Advance resoures is ready to provide services</p>
      </div>
      <div className='Contact-container'>
        <div className="Contact-row">
          <div className="Contact-col">
             <h2>Get in touch</h2>
             <p>you can reach us via following</p>
             <div className="Contact-col1-item" id='headoffice'>
              <div className="circle">
                <FontAwesomeIcon icon={faLocationDot} className='circle-icon' />
              </div>
              <div className="Contact-col1-data">
                <h4>Head Office</h4>
                <p>Office # 53, P (Paradise) Floor, I.T Tower 73-E1 Hali Rd,</p>
                <p>Gulberg III Lahore, Pakistan</p>
              </div>
             </div>
             <div className="Contact-col1-item" id='emailus'>
              <div className="circle">
                <FontAwesomeIcon icon={faEnvelope} className='circle-icon' />
              </div>
              <div className="Contact-col1-data">
                <h4>Email Us</h4>
                <p>info@ar.com.pk</p>
              </div>
             </div>
             <div className="Contact-col1-item" id='callus'>
              <div className="circle">
                <FontAwesomeIcon icon={faPhone} className='circle-icon' />
              </div>
              <div className="Contact-col1-data">
                <h4>Call Us</h4>
                <p >Landline : +92 42 35710318</p>
                <p>Mobile : +92 321 9483047 / +92 321 9448697</p>
              </div>
             </div>
             
             <SocialIcons location='contact'/>
          </div>
          <div className="Contact-col">
            <h2>Send us a Message</h2>
            <BookApointment location="contact"/>
          </div>
        </div>
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13605.190739295545!2d74.3230169087329!3d31.515982968671224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190458d32a5d45%3A0x8cbd6ce309877ba4!2sI.T.%20Tower!5e0!3m2!1sen!2sus!4v1718442388519!5m2!1sen!2sus" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>  
      <Ending BookApointmentLocation="footer"/>
    </div>
  )
}
