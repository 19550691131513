import React from 'react'
import './App.css'
import { Slideshow } from './components/Slideshow';
import { Navbar } from './components/Navbar';
import { Intro } from './components/Intro';
import {ProjectSlideshow} from "./components/ProjectSlideshow";
import {ServicesAndSolutions} from './components/ServicesAndSolutions';
import {Whatsapp} from "./components/Whatsapp";
import { CustomerReviewCount } from './components/CustomerReviewCount';

function App() {

  return (
    <div >
      <div className='slideshowcontainer'>
        <Navbar variant='absolute'/>
        <Slideshow/>
      </div>
        <CustomerReviewCount/>
        <Intro/>
        <ProjectSlideshow/>
        <ServicesAndSolutions/>
        <Whatsapp/>

    </div>
  );
}

export default App;
