import React, { useState, useEffect } from 'react';
import './CSS/MessageSent.css';

export const MessageSent = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 4000); // 4 seconds

    return () => clearTimeout(timer);
  }, []);

  if (!visible) return null; // when component loads it runs for 4 sec then useeffect hoooks make it visibilty false which returns null and component doesnt get chance to render

  return (
    <div className="message-sent-container">
      <div className="message-sent">
        Message sent
        <span className="message-line"></span>
      </div>
    </div>
  );
}