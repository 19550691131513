import React, { useState } from 'react';
import './CSS/Navbar.css';
import logo from '../Images/Navbar/logo.png';
import home from '../Images/Navbar/home.png';
import about from '../Images/Navbar/about.png';
import projects from '../Images/Navbar/project.png';
import products from '../Images/Navbar/product.png';
import solutions from '../Images/Navbar/solution.png';
import Contacts from '../Images/Navbar/contact.png';
import { Link } from 'react-router-dom';

export const Navbar = (props) => {
  const [menuopen, setMenuopen] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  function handleMenuToggle() {
    if (menuopen) {
      setAnimateOut(true);
      setTimeout(() => setAnimateOut(false), 1500);
    }
    setMenuopen(!menuopen); // toggle menu to open/close after clicking on menu btn
  }

  return (
    <div className={`nav-container ${props.variant === 'absolute' ? 'absolue' : 'relative'}`}>
      <div className='nav-logo'>
        <img src={logo} alt="logo" />
      </div>
      <input
        className='menu-btn'
        type='checkbox'
        id='menu-btn'
        checked={menuopen}
        onChange={handleMenuToggle}
      />
      <label className='menu-icon' htmlFor='menu-btn'>
        <span className='nav-icon'></span>
      </label>
      <div className={`nav-items ${animateOut ? 'slide-out' : menuopen ? 'slide-in' : ''}`}>
        <Link to='/' className='nav-button'>
          <img src={home} className='icons' alt="Home Icon" />Home
        </Link>
        <Link to='/about' className='nav-button'>
          <img src={about} className='icons' alt="About Icon" />About
        </Link>
        <Link to='/projects' className='nav-button'>
          <img src={projects} className='icons' alt="Projects Icon" />Projects
        </Link>
        <Link to='/products' className='nav-button'>
          <img src={products} className='icons' alt="Products Icon" />Products
        </Link>
        <Link to='/solutions' className='nav-button'>
          <img src={solutions} className='icons' alt="Solutions Icon" />Solutions
        </Link>
        <Link to='/contact' className='nav-button'>
          <img src={Contacts} className='icons' alt="Contact Icon" />Contact
        </Link>
      </div>
    </div>
  );
};
